/* Apply Google Fonts */
body {
  font-family: 'Open Sans', sans-serif; /* Default body font */
  background-color: #1a1a1a;
  color: white;
  margin: 0;
  padding: 0;
}

/* Main Container */
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px; /* Set a max width to avoid stretching */
  margin: 0 auto; /* Center the container */
  padding: 20px;
}

/* Headers */
h1, h2, h3 {
  font-family: 'Roboto', sans-serif; /* Use Roboto for headers */
  font-weight: 700;
  color: white;
  margin: 10px 0;
  text-align: center; /* Center the headers */
}

/* App Container */
.App {
  text-align: center;
  background-color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

/* Header */
.App-header {
  font-size: calc(10px + 2vmin);
  padding: 20px;
}

.App-logo {
  height: 150px;
  pointer-events: none;
  margin-bottom: 20px;
}

/* Tabs Navigation */
.App-tabs {
  margin: 20px 0;
  text-align: center; /* Center the tab buttons */
}

.App-tabs button {
  background-color: #2c2c2c;
  color: white;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  transition: background-color 0.3s ease;
}

.App-tabs .active-tab {
  background-color: #1e90ff;
  color: white;
}

.App-tabs button:hover {
  background-color: #3c3c3c;
}

/* Showcase Grid */
.App-showcase, .Websites-showcase {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 40px;
  width: 100%;
  padding: 20px;
  justify-content: center; /* Center the grid content */
}

.App-item, .Website-item {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.App-item:hover, .Website-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.App-item-image, .Website-item-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 15px;
}

.App-item h3, .Website-item h3 {
  margin-top: 10px;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.App-details, .Website-item p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  color: #ccc;
  max-width: 250px; /* Ensure description width matches the image */
  margin: 0 auto; /* Center the description under the image */
  text-align: center; /* Align description text to center */
  white-space: normal; /* Allow text to break into new lines */
}

/* Links in Website showcase */
.Websites-showcase a {
  color: #1e90ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.Websites-showcase a:hover {
  text-decoration: underline;
  color: #87cefa;
}

/* SEO Checker */
.loading-spinner {
  font-size: 24px;
  color: #ccc;
  text-align: center;
  margin-top: 50px;
}

.seo-suggestions {
  background-color: #333;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.seo-suggestions h4 {
  margin-bottom: 10px;
  color: #1e90ff;
}

.seo-suggestions ul {
  list-style-type: disc;
  padding-left: 20px;
}

.seo-suggestions li {
  color: #fff;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .App-showcase, .Websites-showcase {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .App-showcase, .Websites-showcase {
    grid-template-columns: 1fr; /* 1 item per row */
    gap: 20px;
  }
  
  .App-logo {
    height: 120px; /* Reduce logo size for mobile */
  }
  
  .App-tabs button {
    margin: 5px 0;
  }
  
  .App-details {
    font-size: 1rem; /* Adjust text size for mobile */
  }
}